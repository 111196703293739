import Base from "@/x/server/base"
class Coach extends Base {
  getCoachConfig(data) { 
    return this.get("/api/system/config", data).then(this._handleResult);
  }
  coachLogin(data) {
    return this.post("/api/user/message", data).then(this._handleResult);
  }
  getDocuments(data) {
    return this.post("/doc/documents/batch", data).then(this._handleResult);
  }
}
export default new Coach();