import SSE from "@/x/tools/sse"
import Base from "@/x/server/base"
import store from "@/x/store"
class Chat extends Base {
  // Send Chat Message
  // Send a request to the chat application.
  sendMessage(data, { onOpen, onMessage, onEnd }) {
    return new Promise((resolve, reject) => {
      try {
        const appId = store.state.coachConfig.appKey || 'app-BAlodt68wIInMniMVFu5bCYE'
        const apiBaseUrl = store.state.coachConfig.apiBaseUrl || '/ai'
        let resultAnswer = ''
        const source = new SSE(apiBaseUrl + '/v1/chat-messages', {
          headers: {
            Authorization: `Bearer ${appId}`,
            'Content-Type': 'application/json',
          },
          methods: 'POST',
          payload: JSON.stringify(data),
        })
        source.addEventListener(
          'message',
          (response) => {
            try {
              const responseData = JSON.parse(response.data) || {}
              if (onMessage) onMessage(responseData)
              if(responseData.answer) {
                resultAnswer += responseData.answer
              }
            } catch (error) {
              console.error('json error:::', error)
            }
          },
          false
        )
        source.addEventListener('readystatechange', (res) => {
          if(res.readyState === res.source.OPEN) {
            onOpen && onOpen()
          }
          if (res.readyState === res.source.CLOSED) {
            onEnd && onEnd(resultAnswer)
            resolve(resultAnswer)
          }
        })
        source.stream()
      } catch (error) {
        reject(error)
      }
    })
  }
  // Stop Generate
  // Only supported in streaming mode.
  stopGenerate(taskId, data) {
    return this.difyPost(`/v1/chat-messages/${taskId}/stop`, data).then(this._handleResult);
  }
  // Message Feedback
  // End-users can provide feedback messages, facilitating application developers to optimize expected outputs.
  messageFeedback(messageId, data) {
    return this.difyPost(`/v1/messages/${messageId}/feedbacks`, data).then(this._handleResult);
  }
  // Get Conversation History Messages
  // Returns historical chat records in a scrolling load format, with the first page returning the latest {limit} messages, i.e., in reverse order.
  getHistoryMessages(data) {
    return this.difyGet("/v1/messages", data).then(this._handleResult);
  }
  // next suggested questions
  // Get next questions suggestions for the current message
  getSuggested(messageId, data) {
    return this.difyGet(`/v1/messages/${messageId}/suggested`, data).then(this._handleResult);
  }
  // Get Conversations
  // Retrieve the conversation list for the current user, defaulting to the most recent 20 entries.
  getConversations(data) {
    return this.difyGet("/v1/conversations", data).then(this._handleResult);
  }
  // Delete Conversation
  deleteConversation(conversationId, data) {
    return this.difyDelete(`/v1/conversations/${conversationId}`, data).then(this._handleResult);
  }
  // Conversation Rename
  renameConversation(conversationId, data) {
    return this.difyPost(`/v1/conversations/${conversationId}/name`, data).then(this._handleResult);
  }
  // Get Application Information
  // Used at the start of entering the page to obtain information such as features, input parameter names, types, and default values.
  getParameters(data) {
    return this.difyGet("/v1/parameters", data).then(this._handleResult);
  }
  // Get Application Meta Information
  // Used to get icons of tools in this application
  getMeta(data) {
    return this.difyGet("/v1/meta", data).then(this._handleResult);
  }
}
export default new Chat();