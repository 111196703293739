import apis from "@/x/server"
import { ElLoading } from 'element-plus';
import { getQyWechatAuthCode } from "@/x/tools/wecom"

export default {
  namespaced: true,
  state: {
    rebotVersion: 1,
    appId: '',
    userInfo: { userId: ''  },
    coachConfig: {},
    conversationList: [],
    currentConversation: null,
    historyMessages: [],
    globalParameters: {},
    nextSuggested: [],
    tokenExpired: '',
    feedbackOptions: []
  },
  mutations: {
    setRebotVersion(state, rebotVersion) {
      state.rebotVersion = rebotVersion
    },
    setAppId(state, appId) {
      state.appId = appId
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
      state.tokenExpired = +new Date() + 1000 * 60 * 60 * 24
    },
    setCoachConfig(state, coachConfig) {
      state.coachConfig = coachConfig
    },
    setGlobalParameters(state, globalParameters) {
      state.globalParameters = globalParameters
    },
    setConversationList(state, conversationList) {
      state.conversationList = conversationList
    },
    setCurrentConversation(state, currentConversation) {
      state.currentConversation = currentConversation
    },
    setHistoryMessages(state, historyMessages) {
      state.historyMessages = historyMessages
    },
    setNextSuggested(state, nextSuggested) {
      state.nextSuggested = nextSuggested
    },
    setFeedbackOptions(state, feedbackOptions) {
      state.feedbackOptions = feedbackOptions
    }
  },
  getters: {
    isTokenExpaired(state) { return state.tokenExpired < +new Date() }
  },
  actions: {
    async autoQyWXLogin({ state, commit }, callback) {
      const auth = await getQyWechatAuthCode()
      if (auth && auth.jsCode) {
        const { code, data } = await apis.login.userLogin({
          code: auth.jsCode,
          corpId: state.coachConfig.corpId || 'wwc1063d1cdd06e827'
        })
        if(code === 200) {
          commit('setUserInfo', data)
          callback && callback()
        }
      }
    },
    async coachLogin({ commit }, params) {
      const { code, data} = await apis.coach.coachLogin(params)
      if(code === 200) {
        commit('setUserInfo', data)
      }
    },
    async getCoachConfig({ commit }, params) {
      const { code, data} = await apis.rebot.getRebotConfig(params)
      if(code === 200) {
        commit('setCoachConfig', data)
      }
    },
    async getParameters({ commit }) {
      const res = await apis.rebot.getParameters()
      commit('setGlobalParameters', res)
    },
    async getConversations({ state, commit }) {
      const res = await apis.rebot.getConversations({
        user: state.userInfo.userId
      })
      commit("setConversationList", res.data)
      // commit("setCurrentConversation", null)
    },
    async getHistoryMessages({ state, commit }) {
      const loadingInstance = ElLoading.service({
        background: 'rgba(0,0,0,.5)'
      })
      const conversation_id = state.currentConversation?.id === 'default' ? '' : state.currentConversation?.id
      try {
        const res = await apis.rebot.getHistoryMessages({
          user: state.userInfo.userId,
          conversation_id: conversation_id || ''
        })
        if(res) {
          commit('setHistoryMessages', res.data)
          loadingInstance.close()
        }
      } catch (error) {
        if([400, 404].includes(error.response.status)) {
          commit("setCurrentConversation", null)
          commit('setHistoryMessages', [])
          loadingInstance.close()
        }
      }
    },
    startNewChat({ state, commit }) {
      if(state.conversationList.length === 0) {
        state.conversationList.unshift({ name: '新对话', id: '' })
      } else if(state.conversationList[0].id) {
        state.conversationList.unshift({ name: '新对话', id: '' })
      }
      commit("setConversationList", state.conversationList)
      commit('setCurrentConversation', state.conversationList[0])
      commit('setHistoryMessages', [])
    },
    async getFeedbackOptions({ commit }) {
      const { code, data } = await apis.rebot.getFeedbackOptions()
      if(code === 200) {
        commit('setFeedbackOptions', data)
      }
    }
  }
}