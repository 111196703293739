import Base from "@/x/server/base"
import SSE from "@/x/tools/sse"
import store from "@/x/store"
class Rebot extends Base {
  sendMessage(data, { onOpen, onMessage, onEnd }) {
    return new Promise((resolve, reject) => {
      try {
        const apiBaseUrl = store.state.$rebot.coachConfig.apiBaseUrl
        const appId = store.state.$rebot.coachConfig.appKey || 'app-zml45MPHddslUpw7mGaybwc4'
        let resultAnswer = ''
        let isAbort = false
        const source = new SSE(`${apiBaseUrl}/v1/chat-messages`, {
          headers: {
            Authorization: `Bearer ${appId}`,
            'Content-Type': 'application/json',
          },
          methods: 'POST',
          payload: JSON.stringify(data),
        })
        source.addEventListener(
          'message',
          (response) => {
            try {
              const responseData = JSON.parse(response.data) || {}
              if (onMessage) onMessage(responseData)
              if(responseData.answer) {
                resultAnswer += responseData.answer
              }
            } catch (error) {
              console.error('json error:::', error)
            }
          },
          false
        )
        source.addEventListener('readystatechange', (res) => {
          if(res.readyState === res.source.OPEN) {
            onOpen && onOpen()
          }
          if (res.readyState === res.source.CLOSED && !isAbort) {
            onEnd && onEnd(resultAnswer)
          }
        })
        source.stream()
        resolve({
          close: function() {
            isAbort = true
            source.close()
          }
        })
      } catch (error) {
        reject(error)
      }
    })
  }

  getParameters(data) {
    const apiBaseUrl = store.state.$rebot.coachConfig.apiBaseUrl
    return this.get(`${apiBaseUrl}/v1/parameters`, data, {
      headers: {
        Authorization: `Bearer ${store.state.$rebot.coachConfig.appKey || 'app-zml45MPHddslUpw7mGaybwc4'}`,
        'Content-Type': 'application/json',
      }
    }).then(this._handleResult);
  }
  getConversations(data) {
    const apiBaseUrl = store.state.$rebot.coachConfig.apiBaseUrl
    return this.get(`${apiBaseUrl}/v1/conversations`, data, {
      headers: {
        Authorization: `Bearer ${store.state.$rebot.coachConfig.appKey || 'app-zml45MPHddslUpw7mGaybwc4'}`,
        'Content-Type': 'application/json',
      }
    }).then(this._handleResult);
  }

  getHistoryMessages(data) {
    const apiBaseUrl = store.state.$rebot.coachConfig.apiBaseUrl
    return this.get(`${apiBaseUrl}/v1/messages`, data, {
      headers: {
        Authorization: `Bearer ${store.state.$rebot.coachConfig.appKey || 'app-zml45MPHddslUpw7mGaybwc4'}`,
        'Content-Type': 'application/json',
      } 
    }).then(this._handleResult);
  }

  // Delete Conversation
  deleteConversation(conversationId, data) {
    const apiBaseUrl = store.state.$rebot.coachConfig.apiBaseUrl
    return this.delete( `${apiBaseUrl}/v1/conversations/${conversationId}`, data, {
      headers: {
        Authorization: `Bearer ${store.state.$rebot.coachConfig.appKey || 'app-zml45MPHddslUpw7mGaybwc4'}`,
        'Content-Type': 'application/json',
      }
    }).then(this._handleResult);
  }

  // Conversation Rename
  renameConversation(conversationId, data) {
    const apiBaseUrl = store.state.$rebot.coachConfig.apiBaseUrl
    return this.post(`${apiBaseUrl}/v1/conversations/${conversationId}/name`, data, {
      headers: {
        Authorization: `Bearer ${store.state.$rebot.coachConfig.appKey || 'app-zml45MPHddslUpw7mGaybwc4'}`,
        'Content-Type': 'application/json',
      }
    }).then(this._handleResult);
  }

  messageFeedback(messageId, data) {
    const apiBaseUrl = store.state.$rebot.coachConfig.apiBaseUrl
    return this.post(`${apiBaseUrl}/v1/messages/${messageId}/feedbacks`, data, {
      headers: {
        Authorization: `Bearer ${store.state.$rebot.coachConfig.appKey || 'app-zml45MPHddslUpw7mGaybwc4'}`,
        'Content-Type': 'application/json',
      } 
    }).then(this._handleResult);
  }

  getSuggested(messageId, data) {
    const apiBaseUrl = store.state.$rebot.coachConfig.apiBaseUrl
    return this.get(`${apiBaseUrl}/v1/messages/${messageId}/suggested`, data, {
      headers: {
        Authorization: `Bearer ${store.state.$rebot.coachConfig.appKey || 'app-zml45MPHddslUpw7mGaybwc4'}`,
        'Content-Type': 'application/json',
      } 
    }).then(this._handleResult);
  }

  getRebotConfig(data) { 
    return this.get("/api/system/config", data).then(this._handleResult);
  }
  
  getRebotQuestions(params) {
    return this.get("/api/bot/questions", {
      bot_id: store.state.$rebot.appId,
      ...params,
      noself: true,
    }).then(this._handleResult);
  }

  putMessageFeedback(data) {
    return this.post(`/api/bot/feedback`, data).then(this._handleResult);
  }

  deleteFeedback(id) {
    return this.delete(`/api/bot/feedback/${id}`).then(this._handleResult);
  }

  getFeedbackOptions() {
    return this.get("/api/bot/feedback/options").then(this._handleResult);
  }

  getTreeData() {
    return this.get("/api/api/tree/1").then(this._handleResult);
  }
}
export default new Rebot();