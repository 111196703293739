import store from '@/x/store'
export default function (to, from, next) {
  let title = ''
  if(to.name === 'chat') {
    title = store.state.coachConfig.appName || ''
  } else if (to.meta.title) {
    title = to.meta.title
  }
  document.title = title
  next()
}