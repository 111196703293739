import { getQueryString, loadScript } from "../tools"
import store from "@/x/store"
export const getQyWechatAuthCode = async function () {
  const code = getQueryString('code')
  const usedStr = localStorage.getItem('usedCodeArray')
  let usedCodeArr = usedStr ? usedStr.split(',') : []
  if (!code || usedCodeArr.includes(code)) {
    const [host, search] = location.href.split('?')
    let norUrl = host
    if (search) {
      norUrl += `?${search.split('&').filter(item => (item.split('=')[0] !== 'code')).join('&')}`
    }
    const appid = getQyAppId()
    const agentid = getAgentId()
    console.log(`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(norUrl)}&response_type=code&scope=snsapi_privateinfo&agentid=${agentid}#wechat_redirect`)
    location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(norUrl)}&response_type=code&scope=snsapi_privateinfo&agentid=${agentid}#wechat_redirect`
  } else {
    usedCodeArr.unshift(code)
    if (usedCodeArr.length > 5) {
      usedCodeArr = usedCodeArr.splice(0, 5)
    }
    localStorage.setItem('usedCodeArray', usedCodeArr.join(','))
    await loadScript('https://res.wx.qq.com/open/js/jweixin-1.2.0.js')
    return {
      jsCode: code,
      authScope: 'SNSAPI_USERINFO' // SNSAPI_BASE
    }
  }
}

function getQyAppId() {
  // switch(location.host) {
  //   case 'ai.megameta.cn':
  //     return 'wwc1063d1cdd06e827'
  //   case 'aita.top-china.cn':
  //     return 'wx7a098f113b89349f'
  // }
  return store.state.$rebot.coachConfig.corpId || 'wwc1063d1cdd06e827'
}

function getAgentId() {
  // switch(location.host) {
  //   case 'ai.megameta.cn':
  //     return '1000025'
  //   case 'aita.top-china.cn':
  //     return '1000074'
  // }
  // return '1000025' 
  return store.state.$rebot.coachConfig.agentId || '1000025'
}

