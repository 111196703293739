import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import chat from './modules/chat';
import rebot from "./modules/rebot";
export default createStore({
  plugins: [ createPersistedState({ key: 'jh-chat' }) ],
  modules: {
    $chat: chat,
    $rebot: rebot
  }
});